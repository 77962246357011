<script setup>
import {ref} from "vue";

const props = defineProps({
  title: String,
  advices: Object,
  img: String,
});

const advices = ref(props.advices);
const adviceIndex = ref(0);

const nextAdvice = () => {
  console.log("next advice");
  adviceIndex.value = (adviceIndex.value + 1) % advices.value.length;
};
</script>

<template>
  <div class="row bg-white flex-md-row mt-3 mb-3">
    <div class="col-lg-6 col-md-12" style="padding: 0">
      <img :src="img" class="img-fluid" :alt="title">
    </div>
    <div class="col-lg-6 col-md-12 px-4 py-3 d-flex flex-column justify-content-between align-items-start">
      <h4 v-html="title"></h4>
      <div class="advice-content">
        <Transition  name="slide-fade" mode="out-in">
          <p :key="adviceIndex" v-html="advices[adviceIndex]"/>
        </Transition>
      </div>
      <div class="btn mt-2 secondary-btn" @click="nextAdvice">+ de conseils</div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.advice-content {
  height: 92px;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(15px);
  opacity: 0;
}

</style>